"use client";

import { useEffect } from "react";
import { useSearchParams, usePathname } from "next/navigation";

import { ReferralSource, setLeadSourceCookie } from "@/app/lib/actions/referrer";
export const UTM_PARAMS = ["utm_source", "utm_medium", "utm_campaign", "utm_content", "utm_term"];

export default function ReferralTracker() {
    const searchParams = useSearchParams();
    const pathname = usePathname();

    useEffect(() => {
        const initializeTracking = async () => {
            const source = determineSource();
            console.log(source);
            if (source) {
                await setLeadSourceCookie(source);
            }
        };
        initializeTracking();
    }, [searchParams, pathname]);

    const getUtmSource = () => {
        const utmData: Partial<ReferralSource> = {};
        let hasUtm = false;

        for (const param of UTM_PARAMS) {
            const value = searchParams.get(param);
            if (value) {
                const key = param.replace("utm_", "") as keyof ReferralSource;
                (utmData[key] as string | undefined) = value;
                hasUtm = true;
            }
        }

        if (hasUtm && utmData.source) {
            return {
                ...(utmData as ReferralSource),
                type: "utm"
            };
        }

        return null;
    };

    const getReferrerInfo = () => {
        if (typeof document === "undefined") return null;

        const referrer = document.referrer;
        if (!referrer) return null;

        try {
            const referrerUrl = new URL(referrer);
            const referrerDomain = referrerUrl.hostname;

            if (referrerDomain === window.location.hostname) {
                return null;
            }

            return {
                type: "referrer",
                source: referrerDomain,
                medium: "referral",
                referrer_url: referrer,
                timestamp: Date.now()
            } as ReferralSource;
        } catch (error) {
            return null;
        }
    };

    const determineSource = (): ReferralSource | null => {
        // Check UTM parameters first
        const utmSource = getUtmSource();
        if (utmSource) {
            return {
                ...utmSource,
                type: "utm",
                timestamp: Date.now()
            };
        }

        // Check referrer
        const referrerSource = getReferrerInfo();
        if (referrerSource) {
            return {
                ...referrerSource,
                type: "referrer",
                timestamp: Date.now()
            };
        }

        return {
            type: "direct",
            source: "direct",
            medium: "none",
            timestamp: Date.now()
        };
    };

    return null;
}
